<template>
<div>
  <post1 :json="2" accroche="" date="" auteur="" fonction="" avatar="" comments="" like="" xml="" authorinfo="" />
</div>
</template>


<script>
const eurocurrency = new Intl.NumberFormat('fr-FR', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2
});

import post1 from "@/components/post1.vue";


export default {
  name: 'faq',
  components: {post1},
  props: {},
  data: () => ({}),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed: {},
  methods: {},
  watch: {}
}
</script>

<style scoped>
</style>
