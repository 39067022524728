<template>
<div>
  <post1 :json="json" accroche="Avantages et méthodes" date="" auteur="" fonction="" avatar="" comments="" like="" xml="" authorinfo="" />
</div>
</template>


<script>


import post1 from "@/components/post1.vue";


export default
{
  name: 'sci',
  components:
  {
    post1
  },
  props:
  {},
  data: () => (
  {

  json:"3",



  }),
  beforeCreate: function() {},
  created: function() {},
  beforeMount: function() {},
  mounted: function() {},
  beforeUpdate: function() {},
  updated: function() {},
  beforeDestroy: function() {},
  destroyed: function() {},
  computed:
  {},
  methods:
  {},
  watch:
  {}
}
</script>

<style scoped>
</style>
